<template>
  <div class="container-fluid p-4">
    <router-link
      :to="{
        name: 'reports'
      }"
      v-slot="{ href, route, navigate }"
      custom
    >
      <a :href="href" @click="navigate" class="mr-2"
        ><i class="fa fa-chevron-left mr-1"></i>Back</a
      >
    </router-link>
    <div class="d-flex flex-column pt-4"></div>
    <div class="d-flex justify-content-between">
      <h1>Report On Demand</h1>
      <div class="d-flex justify-content-between">
        <h1 class="mr-3">Total :{{ totalRows }}</h1>
      </div>
    </div>
    <div class="d-flex flex-column pt-4">
      <div class="d-flex flex-column">
        <b-card-group>
          <b-card class="bg-surface-2">
            <b-row class="d-flex flex-row justify-content-between">
              <b-col>
                <date-range-picker
                  ref="picker"
                  class="w-100 ml-auto"
                  v-model="queryForm.dateRange"
                  :locale-data="{
                    firstDay: 1,
                    format: 'dd-mm-yyyy HH:mm'
                  }"
                  :time-picker-increment="1"
                  :timePicker="true"
                  :time-picker-seconds="true"
                >
                  <template v-slot:input="picker">
                    {{ picker.startDate | dateTime }} -
                    {{ picker.endDate | dateTime }}
                  </template>
                </date-range-picker>
              </b-col>
            </b-row>
            <b-row class="d-flex justify-content-end" no-gutters>
              <b-button
                size="sm"
                variant="dark"
                @click="clearFilter"
                class="d-flex justify-content-end mr-1"
                >Clear
              </b-button>
              <b-button size="sm" variant="light" @click="getSearch">
                Search
              </b-button>
            </b-row>
          </b-card>
        </b-card-group>
      </div>

      <div class="d-flex justify-content-end pt-4 pb-2 b">
        <b-button :disabled="result.length === 0" @click="downloadVMSPdf()">
          <b-spinner small v-if="result.length === 0" class="mr-1"></b-spinner>
          <i class="fas fa-file-pdf"></i> Export as PDF
        </b-button>
        <download-excel
          class="ml-1 btn btn-secondary"
          :class="result.length === 0 ? 'disabled' : null"
          :data="result"
          :fields="jsonVMSFields"
          worksheet="My Worksheet"
          type="csv"
          :title="
            queryForm.dateRange.startDate +
            ' to ' +
            queryForm.dateRange.endDate +
            ' System Logs'
          "
          :name="
            queryForm.dateRange.startDate +
            ' to ' +
            queryForm.dateRange.endDate +
            ' Report.csv'
          "
          :before-generate="startDownloadCSV"
          :before-finish="finishDownloadCSV"
        >
          <b-spinner small v-if="result.length === 0" class="mr-1"></b-spinner>
          <i class="fas fa-file-csv"></i> Export as CSV
        </download-excel>
      </div>

      <div class="d-flex justify-content-between">
        <!-- Main table element -->
        <b-table
          id="temp-target"
          class="tableBorder"
          stacked="md"
          striped
          small
          show-empty
          sticky-header
          :busy="busy"
          :items="result"
          :fields="resultTableFields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="searchTerms"
          :filter-included-fields="['name']"
          :total-rows="totalRows"
          :per-page="perPage"
          :current-page="currentPage"
          @sort-changed="sortChanged"
        >
          <template #table-busy>
            <div class="text-center text-white my-2">
              <div class="my-5">
                <strong>Please wait. It may take up to a minute...</strong>
              </div>
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>

          <template #cell(index)="row">
            {{ row.index + 1 + (currentPage - 1) * perPage }}
          </template>
          <template #cell(type)="row">
            {{ row.item.type | humanizeText }}
          </template>
          <template #cell(visit_code)="row">
            {{ row.item.visit_code | dashForEmptyText }}
          </template>
          <template #cell(timestamp)="row">
            {{ row.item.timestamp | created | dashForEmptyText }}
          </template>
          <template #cell(check_in_time)="row">
            {{ row.item.check_in_time | timeIn | dashForEmptyText }}
          </template>
          <template #cell(check_out_time)="row">
            {{ row.item.check_out_time | timeOut | dashForEmptyText }}
          </template>
          <template #cell(visitor_name)="row">
            {{ row.item.visitor_name | humanizeText | dashForEmptyText }}
          </template>
          <template #cell(company_name)="row">
            <div>
              <strong>{{ row.item.company_name | humanizeText }}</strong>
            </div>
            <div>
              {{ row.item.host_name | humanizeText }}
            </div>
          </template>
        </b-table>
      </div>
      <!--pagination-->
      <div class="d-flex justify-content-between w-100" v-if="!busy && !search">
        <div class="d-flex">
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
            @change="handlePerPageChange"
          ></b-form-select>
          Per Page
        </div>

        <div class="d-flex">
          <b-pagination
            size="sm"
            v-if="perPage !== 'all'"
            class="d-flex"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            @change="handlePageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import JsonExcel from "vue-json-excel";
import DateRangePicker from "vue2-daterange-picker";
import jsPDF from "jspdf";
import _ from "lodash";
import "jspdf-autotable";

//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: {
    DateRangePicker,
    downloadExcel: JsonExcel
  },
  data: function () {
    let yesterday = moment().subtract(1, "days").toDate();
    yesterday.setMinutes(0);
    yesterday.setSeconds(0);
    yesterday.setMilliseconds(0);

    let today = moment().toDate();
    return {
      pageTitle: "Reports",
      dateRange: {
        startDate: moment().subtract(1, "days").toDate(),
        endDate: moment().toDate()
      },
      result: [],
      company: [],
      resultTableFields: [
        {
          key: "index",
          label: "No.",
          thStyle: { width: "3%" }
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
          thStyle: { width: "auto" }
        },
        {
          key: "visit_code",
          label: " Visitation Code",
          sortable: true,
          thStyle: { width: "auto" }
        },
        {
          key: "timestamp",
          label: "Event Created Date",
          sortable: true,
          thStyle: { width: "auto" }
        },
        {
          key: "check_in_time",
          label: "Time In",
          sortable: true,
          thStyle: { width: "auto" }
        },
        {
          key: "check_out_time",
          label: "Time Out",
          sortable: true,
          thStyle: { width: "auto" }
        },
        {
          key: "visitor_name",
          label: "Visitor Name",
          sortable: true,
          thStyle: { width: "auto" }
        },
        {
          key: "company_name",
          label: "Host Company",
          sortable: true,
          thStyle: { width: "auto" }
        }
      ],
      // pagination
      totalRows: 5,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      //sortBy: "check_in_time",
      sortBy: null,
      sortDesc: null,
      sortDirection: "desc",
      filter: null,

      jsonVMSFields: {
        "Event Type": {
          field: "type",
          callback: (value) => {
            try {
              return _.startCase(value);
            } catch (e) {
              return "-";
            }
          }
        },
        "Visitation Code": {
          field: "visit_code",
          callback: (value) => {
            try {
              if (value != "") {
                return value;
              } else {
                return "-";
              }
            } catch (e) {
              return "-";
            }
          }
        },
        "Time in": {
          field: "check_in_time",
          callback: (value) => {
            let time = value;
            try {
              if (value && value != null) {
                return moment(value * 1000).format("DD/MM/YYYY HH:mm A");
              } else {
                return "-";
              }
            } catch (e) {
              return "-";
            }
          }
        },
        // "ACS Person No": "data.metadata.access_card_no",
        "Time out": {
          field: "check_out_time",
          callback: (value) => {
            let time = value;
            try {
              if (value && value != null) {
                return moment(value * 1000).format("DD/MM/YYYY HH:mm A");
              } else {
                return "-";
              }
            } catch (e) {
              return "-";
            }
          }
        },
        // "Access Group": {
        //   field: "data.metadata.access_metadata",
        //   callback: (value) => {
        //     let access = value;
        //     try {
        //       if (value != "") {
        //         for (let key in access) {
        //           for (let key2 in access[key]) {
        //             for (let key3 in access[key][key2]) {
        //               let access_group = access[key][key2][key3];
        //               return access_group.access_groups;
        //             }
        //           }
        //         }
        //       } else {
        //         return "-";
        //       }
        //     } catch (e) {
        //       return "-";
        //     }
        //   }
        // },
        // "Access Level": {
        //   field: "data.metadata.access_metadata",
        //   callback: (value) => {
        //     let access = value;
        //     try {
        //       if (value != "") {
        //         for (let key in access) {
        //           for (let key2 in access[key]) {
        //             for (let key3 in access[key][key2]) {
        //               let access_group = access[key][key2][key3];
        //               return access_group.card_access_level;
        //             }
        //           }
        //         }
        //       } else {
        //         return "-";
        //       }
        //     } catch (e) {
        //       return "-";
        //     }
        //   }
        // },
        "Visitor Name": {
          field: "visitor_name",
          callback: (value) => {
            try {
              return _.startCase(value);
            } catch (e) {
              return "-";
            }
          }
        },
        // "Visitor's Company": {
        //   field: "company",
        //   callback: (value) => {
        //     let company = value;
        //     try {
        //       if (value != "") {
        //         for (let key in company) {
        //           return company[key].name;
        //         }
        //       } else {
        //         return "-";
        //       }
        //     } catch (e) {
        //       return "-";
        //     }
        //   }
        // },
        "Host Name": {
          field: "host_name",
          callback: (value) => {
            try {
              return _.startCase(value);
            } catch (e) {
              return "-";
            }
          }
        },
        "Host Company": {
          field: "company_name",
          callback: (value) => {
            let company = value;
            try {
              return _.startCase(value);
            } catch (e) {
              return "-";
            }
          }
        }
      },
      // table search
      searchTerms: "",
      busy: false,
      search: false,
      queryForm: {
        event_type: { value: "vms", name: "Smart VMS" },
        dateRange: {
          startDate: yesterday,
          endDate: today
        },
        limit: 100,
        subevent_type: "report_on_demand"
      },
      eventTypeOptions: [{ value: "vms", name: "Smart VMS" }],
      limitOptions: [
        /* { value: "10", name: "10" }, */
        { value: 100, name: "100" },
        { value: 500, name: "500" },
        { value: 1000, name: "1000" },
        { value: null, name: "Custom" }
      ],
      showCustomLimit: false
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL",
      getCurrentScMode: "session/getScMode"
    })
  },
  watch: {
    $route: function () {
      if (this.$route.query.per_page === "all") {
        this.currentPage = 1;
        this.perPage = this.$route.query.per_page;
      } else {
        this.currentPage = this.$route.query.page
          ? parseInt(this.$route.query.page)
          : 1;
        this.perPage = this.$route.query.per_page
          ? parseInt(this.$route.query.per_page)
          : 15;
      }
      this.getPagination();
      this.getAllData();
    }
  },
  created() {
    if (this.$route.query.per_page === "all") {
      this.currentPage = 1;
      this.perPage = this.$route.query.per_page;
    } else {
      this.currentPage = this.$route.query.page
        ? parseInt(this.$route.query.page)
        : 1;
      this.perPage = this.$route.query.per_page
        ? parseInt(this.$route.query.per_page)
        : 15;
    }
  },
  filters: {
    created(value) {
      let access = value;
      try {
        if (value) {
          return moment(value * 1000).format("DD/MM/YYYY HH:mm A");
        } else {
          return "-";
        }
      } catch (e) {
        return "-";
      }
    },
    timeIn(value) {
      let access = value;
      try {
        if (value) {
          return moment(value * 1000).format("DD/MM/YYYY HH:mm A");
        } else {
          return "-";
        }
      } catch (e) {
        return "-";
      }
    },
    timeOut(value) {
      let access = value;
      try {
        if (value) {
          return moment(value * 1000).format("DD/MM/YYYY HH:mm A");
        } else {
          return "-";
        }
      } catch (e) {
        return "-";
      }
    },
    visitorName(value) {
      let visitor = value.visitors;
      try {
        for (let key in visitor) {
          return visitor[key].name;
        }
      } catch (e) {
        return "-";
      }
    },
    hostName(value) {
      let host = value.hosts;
      try {
        for (let key in host) {
          return host[key].name;
        }
      } catch (e) {
        return "-";
      }
    },
    companyName(value) {
      let company = value.company;
      try {
        for (let key in company) {
          return company[key].name;
        }
      } catch (e) {
        return "-";
      }
    },
    visitCode(value) {
      try {
        return value.data.metadata.visit_code;
      } catch (e) {
        return "-";
      }
    }
    // accessGroup(value) {
    //   let access = value.data.metadata.access_metadata;
    //   try {
    //     for (let key in access) {
    //       for (let key2 in access[key]) {
    //         for (let key3 in access[key][key2]) {
    //           let access_group = access[key][key2][key3];
    //           return access_group.access_groups;
    //         }
    //       }
    //     }
    //   } catch (e) {
    //     return "-";
    //   }
    // },
    // accessLevel(value) {
    //   let access = value.data.metadata.access_metadata;
    //   try {
    //     for (let key in access) {
    //       for (let key2 in access[key]) {
    //         for (let key3 in access[key][key2]) {
    //           let access_group = access[key][key2][key3];
    //           return access_group.card_access_level;
    //         }
    //       }
    //     }
    //   } catch (e) {
    //     return "-";
    //   }
    // },
  },
  mounted() {
    this.getAllData();
    this.getPagination();
    //this.getData();
  },
  methods: {
    getSearch() {
      this.getAllData();
      this.getPagination();
    },
    getAllData() {
      let $this = this;
      let query = {};
      query = {
        start_date: moment($this.queryForm.dateRange.startDate).toISOString(),
        end_date: moment($this.queryForm.dateRange.endDate).toISOString(),
        event_type: $this.queryForm.event_type.value,
        limit: $this.queryForm.limit,
        subevent_type: $this.queryForm.subevent_type
      };
      query = Object.entries(query).reduce(
        (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
        {}
      );

      let API_URL = $this.getAPIServerURL + "/api/eventservice/events/?";
      let queryString = Object.keys(query)
        .map((key) => key + "=" + query[key])
        .join("&");

      API_URL += queryString;

      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let error = data.detail;
          if (error != null) {
            //
          } else {
            let result = data.result;
            let vms_result_list = [];
            if (result != undefined) {
              outer_loop: for (let key in result) {
                vms_result_list = result[key]["result"];
                if (vms_result_list != undefined) {
                  this.busy = false;
                  this.search = false;
                  break outer_loop;
                }
              }
            }
            if (vms_result_list != undefined) {
              this.result = vms_result_list;
            }
          }
        })
        .catch((err) => {
          console.log("error", err);
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Please try again later",
            message_type: "danger"
          });
        });
    },
    getPagination() {
      let $this = this;
      let query = {};
      query = {
        start_date: moment($this.queryForm.dateRange.startDate).toISOString(),
        end_date: moment($this.queryForm.dateRange.endDate).toISOString(),
        event_type: $this.queryForm.event_type.value,
        limit: $this.queryForm.limit,
        subevent_type: $this.queryForm.subevent_type,
        page_index: this.currentPage,
        page_size: this.perPage
      };

      query = Object.entries(query).reduce(
        (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
        {}
      );

      let API_URL = $this.getAPIServerURL + "/api/eventservice/events/?";
      let queryString = Object.keys(query)
        .map((key) => key + "=" + query[key])
        .join("&");

      API_URL += queryString;

      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let error = data.detail;
          if (error != null) {
            //$this.busy = false;
            //$this.result = [];
            $this.search = true;
          } else {
            let result = data.result;
            let vms_result_list = [];
            if (result != undefined) {
              outer_loop: for (let key in result) {
                vms_result_list = result[key]["result"];
                if (vms_result_list != undefined) {
                  this.busy = false;
                  this.search = false;
                  break outer_loop;
                }
              }
            }
            /* if (vms_result_list != undefined) {
              this.result = vms_result_list["events"];
            } */
            if (vms_result_list["total"] !== undefined) {
              this.totalRows = parseInt(vms_result_list["total"]);
            }

            if (vms_result_list["page"] !== undefined) {
              this.currentPage = parseInt(vms_result_list["page"]);
            }

            if (vms_result_list["size"] !== undefined) {
              this.perPage = parseInt(vms_result_list["size"]);
            }
          }
        })
        .catch((err) => {
          console.log("error", err);
          $this.busy = false;
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Please try again later",
            message_type: "danger"
          });
        });
    },
    fetchVisitors(id) {
      let $this = this;
      let API_URL =
        this.getAPIServerURL + "/api/visitorservice/visit/" + id + "/visitors/";

      const client = this.$root.getAjaxFetchClient();

      client
        .getRequest(API_URL, null, false, false)
        .then((data) => {
          let result = data.result;
          let validUrl = "";
          if (result !== undefined) {
            outer_loop: for (let key in result) {
              if (result[key] !== undefined && Array.isArray(result[key])) {
                validUrl = key;
                break outer_loop;
              }
            }
          }

          if (
            result[validUrl] != undefined &&
            Array.isArray(result[validUrl])
          ) {
            for (let key2 in $this.result) {
              if (id == $this.result[key2].data.metadata.visit_id) {
                $this.result[key2].visitors = result[validUrl];
              }
            }
          }
        })
        .catch((err) => {
          $this.busy = false;
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    fetchHosts(id) {
      let $this = this;
      let API_URL =
        this.getAPIServerURL + "/api/visitorservice/visit/" + id + "/hosts/";

      const client = this.$root.getAjaxFetchClient();

      client
        .getRequest(API_URL, null, false, false)
        .then((data) => {
          let result = data.result;
          let validUrl = "";
          if (result !== undefined) {
            outer_loop: for (let key in result) {
              if (result[key] !== undefined && Array.isArray(result[key])) {
                validUrl = key;
                break outer_loop;
              }
            }
          }
          if (
            result[validUrl] != undefined &&
            Array.isArray(result[validUrl])
          ) {
            for (let key2 in $this.result) {
              if (id == $this.result[key2].data.metadata.visit_id) {
                $this.result[key2].hosts = result[validUrl];
                for (let key3 in $this.result[key2].hosts) {
                  let id = $this.result[key2].hosts[key3].company_id;
                  $this.fetchCompany(id);
                }
              }
            }
          }
        })
        .catch((err) => {
          $this.busy = false;
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    fetchCompany(id) {
      let $this = this;
      let API_URL =
        this.getAPIServerURL + "/api/visitorservice/company/?company_id=" + id;
      let company = [];
      const client = this.$root.getAjaxFetchClient();

      client
        .getRequest(API_URL, null, false, false)
        .then((data) => {
          let result = data.result;
          if (result !== undefined) {
            outer_loop: for (let key in result) {
              company.push(result[key]);
              for (let key2 in $this.result) {
                for (let key3 in $this.result[key2].hosts) {
                  if (id == $this.result[key2].hosts[key3].company_id) {
                    $this.result[key2].company = company;
                  }
                }
              }
              break outer_loop;
            }
          }
        })
        .catch((err) => {
          $this.busy = false;
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },

    //sort
    sortChanged(e) {
      console.log("inside", e);
      this.sortBy = e.sortBy;
      this.sortDesc = e.sortDesc;
      if (this.sortDesc) {
        this.result.sort((x, y) => (x[this.sortBy] > y[this.sortBy] ? -1 : 1));
      } else {
        this.result.sort((x, y) => (y[this.sortBy] > x[this.sortBy] ? -1 : 1));
      }
      console.log("result", this.result);
    },
    //clear filter
    clearFilter: function () {
      let yesterday = moment().subtract(1, "days").toDate();
      yesterday.setMinutes(0);
      yesterday.setSeconds(0);
      yesterday.setMilliseconds(0);

      let today = moment().toDate();
      this.$router.push({
        name: this.$route.query.parents_url
      });
      this.queryForm = {
        event_type: { value: "vms", name: "Smart VMS" },
        dateRange: {
          startDate: yesterday,
          endDate: today
        },
        limit: 100,
        subevent_type: "report_on_demand"
      };
      this.result = [];
      this.busy = false;
      this.getPagination();
      this.getAllData();
    },

    // handle page change
    handlePageChange(page) {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          page: parseInt(page)
        }
      });
    },
    handlePerPageChange(pageSize) {
      if (pageSize === "all") {
        this.$router.push({
          ...this.$route,
          query: {
            per_page: pageSize
          }
        });
      } else {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            per_page: parseInt(pageSize)
          }
        });
      }
    },

    async downloadVMSPdf() {
      if (this.result.length > 0) {
        await this.$store.dispatch("session/addGlobalAlertMessage", {
          message_text:
            "We are generating PDF report with " +
            this.result.length +
            " events",
          message_type: "info"
        });
      }

      let result = this.result;
      let bodyData = [];
      let i = 0;
      for (let key in result) {
        let data2 = [];
        let data3 = [];
        let visitor = result[key].visitors;
        let hosts = result[key].hosts;
        let company = result[key].company;
        i += 1;
        data2.push(i + "");
        if (result[key] == null) {
          data2.push("-");
        } else {
          data2.push(_.startCase(result[key].type));
          data2.push(result[key].visit_code);
          if (result[key].check_in_time) {
            data2.push(
              moment(result[key].check_in_time * 1000).format(
                "DD/MM/YYYY HH:mm A"
              )
            );
          } else {
            data2.push("-");
          }
          if (result[key].check_out_time) {
            data2.push(
              moment(result[key].check_out_time * 1000).format(
                "DD/MM/YYYY HH:mm A"
              )
            );
          } else {
            data2.push("-");
          }
          data2.push(_.startCase(result[key].visitor_name));
          data3.push(_.startCase(result[key].host_name));
          data3.push(_.startCase(result[key].company_name));
          data2.push(data3);
        }
        bodyData.push(data2);
      }
      let doc = new jsPDF();

      doc.text(
        "Report " +
          moment(this.queryForm.dateRange.startDate).format("DD/MM/YYYY") +
          " to " +
          moment(this.queryForm.dateRange.endDate).format("DD/MM/YYYY"),
        14,
        12
      );

      doc.autoTable({
        html: "#my-table"
      });

      doc.autoTable({
        head: [
          [
            "No",
            "Event",
            "Visitation Code",
            "Time IN",
            "Time OUT",
            // "Access Group",
            // "Access Level",
            "Visitor Name",
            // "Visitor Company",
            // "Host Name",
            "Host Company"
          ]
        ],
        body: bodyData,
        // tableWidth: 'auto',
        // columnStyles: {
        //   2: {
        //     cellWidth: 'auto'
        //   }
        // },
        theme: "grid",
        headStyles: { fillColor: [238, 238, 238], textColor: [0, 0, 0] },
        lineColor: [0, 0, 0],
        columnStyles: {
          0: { cellWidth: 10 },
          1: { cellWidth: 30 },
          2: { cellWidth: 25 },
          3: { cellWidth: 23 },
          4: { cellWidth: 23 },
          5: { cellWidth: 30 },
          6: { cellWidth: 40 }
          // 7: { cellWidth: 20 },
          // 8: { cellWidth: 20 }
        },
        bodyStyles: { minCellHeight: 15 }
      });

      doc.save(
        this.queryForm.dateRange.startDate +
          " to " +
          this.queryForm.dateRange.endDate +
          " Report.pdf"
      );

      await this.$store.dispatch("session/addGlobalAlertMessage", {
        message_text:
          "PDF report with " +
          this.result.length +
          " events has been downloaded successfully",
        message_type: "info"
      });
    },
    startDownloadCSV() {
      if (this.result.length > 0) {
        this.$store.dispatch("session/addGlobalAlertMessage", {
          message_text:
            "We are generating CSV report with " +
            this.result.length +
            " events",
          message_type: "info"
        });
      }
    },
    finishDownloadCSV() {
      this.$store.dispatch("session/addGlobalAlertMessage", {
        message_text:
          "CSV report with " +
          this.result.length +
          " events has been downloaded successfully",
        message_type: "info"
      });
    }

    /* handleLimitOnChange(value) {
      if (value === null) {
        this.showCustomLimit = true;
      } else {
        this.showCustomLimit = false;
      }
    },
   getData() {
      let $this = this;
      $this.busy = true;
      let query = {};
      if (this.perPage === "all") {
        query = {
          start_date: moment($this.queryForm.dateRange.startDate).toISOString(),
          end_date: moment($this.queryForm.dateRange.endDate).toISOString(),
          event_type: $this.queryForm.event_type.value,
          limit: $this.queryForm.limit,
          subevent_type: $this.queryForm.subevent_type
        };
      } else {
        query = {
          start_date: moment($this.queryForm.dateRange.startDate).toISOString(),
          end_date: moment($this.queryForm.dateRange.endDate).toISOString(),
          event_type: $this.queryForm.event_type.value,
          limit: $this.queryForm.limit,
          subevent_type: $this.queryForm.subevent_type,
          page_index: this.currentPage,
          page_size: this.perPage
        };
      }
      query = Object.entries(query).reduce(
        (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
        {}
      );

      let API_URL = $this.getAPIServerURL + "/api/eventservice/events/?";
      let queryString = Object.keys(query)
        .map((key) => key + "=" + query[key])
        .join("&");

      API_URL += queryString;

      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let error = data.detail;
          if (error != null) {
            $this.busy = false;
            $this.result = [];
            $this.search = true;
          } else {
            let result = data.result;
            let vms_result_list = [];
            if (result != undefined) {
              outer_loop: for (let key in result) {
                vms_result_list = result[key]["result"];
                if (vms_result_list != undefined) {
                  this.busy = false;
                  this.search = false;
                  break outer_loop;
                }
              }
            }
            if (vms_result_list != undefined) {
              // if (this.perPage == "all") {
              //   this.result = vms_result_list;
              // } else {
              this.result = vms_result_list["events"];
              //   }
            }
            if (vms_result_list["total"] !== undefined) {
              this.totalRows = parseInt(vms_result_list["total"]);
            }

            if (vms_result_list["page"] !== undefined) {
              this.currentPage = parseInt(vms_result_list["page"]);
            }

            if (vms_result_list["size"] !== undefined) {
              this.perPage = parseInt(vms_result_list["size"]);
            }
          }
          //old codee
          // let error = data.detail;
          // if (error != null) {
          //   $this.busy = false;
          // } else {
          //   $this.result = [];
          //   let result = data.result;
          //   if (result !== undefined) {
          //     for (let vms_url in result) {
          //       let vms_result_list = result[vms_url].result;
          //       if (Array.isArray(vms_result_list)) {
          //         if (vms_result_list.length === 0) {
          //           $this.busy = false;
          //         }
          //
          //         for (let key in vms_result_list) {
          //           let vms_data = vms_result_list[key];
          //           $this.result.push(vms_data);

          // let id = vms_data.data.metadata.visit_id;
          //
          // let whitelistedEvents = [
          //   "visit_created",
          //   "visit_access_card_created",
          //   "visit_access_card_updated",
          //   "visit_access_card_deleted",
          //   "visit_checked_in",
          //   "visit_checked_out"
          // ];
          //
          // if (whitelistedEvents.includes(vms_data.subevent_type)) {
          //   vms_data["visitors"] = null;
          //   vms_data["hosts"] = null;
          //   vms_data["company"] = null;
          //   $this.result.push(vms_data);
          //   if (id !== undefined) {
          //     $this.fetchVisitors(id);
          //     $this.fetchHosts(id);
          //   }
          //
          //           $this.busy = false;
          //           // }
          //         }
          //         // $this.busy = false;
          //       }
          //     }
          //   }
          // }
          // last row code
        })
        .catch((err) => {
          console.log("error", err);
          $this.busy = false;
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Please try again later",
            message_type: "danger"
          });
        });
    }, */
  }
};
</script>

<style lang="scss">
.calendars {
  background: var(--colour-nav-bg);
}

.drp-buttons {
  background: var(--colour-nav-bg);
}

.calendar-table {
  color: var(--colour-overlay-gray-dark);
}
</style>
